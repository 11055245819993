import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../components/Login.vue'
// import Home from '../components/Home.vue'
// import Welcome from '../components/Welcome.vue'

// 实现路由懒加载
const Login = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Login.vue')
const Home = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Home.vue')
const Welcome = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Welcome.vue')

// import Users from '../components/user/Users.vue'
// import Rights from '../components/power/Rights.vue'
// import Roles from '../components/power/Roles.vue'
const Users = () =>
    import ( /* webpackChunkName: "users_rights_roles" */ '../components/user/Users.vue')
const Rights = () =>
    import ( /* webpackChunkName: "users_rights_roles" */ '../components/power/Rights.vue')
const Roles = () =>
    import ( /* webpackChunkName: "users_rights_roles" */ '../components/power/Roles.vue')

// import Cate from '../components/goods/Cate.vue'
// import Params from '../components/goods/Params.vue'
const Cate = () =>
    import ( /* webpackChunkName: "cate_params" */ '../components/goods/Cate.vue')
const Params = () =>
    import ( /* webpackChunkName: "cate_params" */ '../components/goods/Params.vue')

// import GoodsList from '../components/goods/List.vue'
// import Add from '../components/goods/Add.vue'
const GoodsList = () =>
    import ( /* webpackChunkName: "goodslist_add" */ '../components/goods/List.vue')
const Add = () =>
    import ( /* webpackChunkName: "goodslist_add" */ '../components/goods/Add.vue')
const ListDetail = () =>
    import ( /* webpackChunkName: "goodslist_add" */ '../components/goods/ListDetail.vue')
const Revise = () =>
    import ( /* webpackChunkName: "goodslist_add" */ '../components/goods/Revise.vue')

// import Order from '../components/order/Order.vue'
// import Report from '../components/report/Report.vue'
const Order = () =>
    import ( /* webpackChunkName: "order_report" */ '../components/order/Order.vue')
const Report = () =>
    import ( /* webpackChunkName: "order_report" */ '../components/report/Report.vue')

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/home',
        component: Home,
        redirect: '/welcome',
        children: [
            // 欢迎页面
            {
                path: '/welcome',
                component: Welcome
            },
            {
                path: '/users',
                component: Users
            },
            {
                path: '/rights',
                component: Rights
            },
            {
                path: '/roles',
                component: Roles
            },
            // 材料分类页面
            {
                path: '/categories',
                component: Cate
            },
            // {
            //     path: '/params',
            //     component: Params
            // },
            // 材料列表页面
            {
                path: '/goods',
                component: GoodsList
            },
            // 添加材料页面
            {
                path: '/add',
                component: Add
            },
            // 材料详情页面
            {
                path: '/listDetail',
                component: ListDetail
            },
            {
                path: '/revise',
                component: Revise
            },
            // 订单列表页面
            {
                path: '/orders',
                component: Order
            },
            // 数据统计页面
            {
                path: '/reports',
                component: Report
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to and from are Route Object,next() must be called to resolve the hook}
    // to将要访问的路径 from代表从哪个路径跳转而来
    // next 是一个函数，表示放行 next() 放行 next('/login') 强制跳转到登录页面
    // 如果是登录页，直接放行即可
    if (to.path === '/login') return next()
    // 获取登陆时存储的信息
    const tokenStr = window.localStorage.getItem('ms_uservalue')
    if (!tokenStr) return next('/login')
    next()
})

export default router