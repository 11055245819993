import Vue from 'vue'
import Vuex from 'vuex' 
import App from './App.vue'
import router from './router'
// import store from './store'
import * as echarts from 'echarts'
// import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入tree-tabel
import TreeTable from 'vue-table-with-tree-grid'
// 导入axios包
// import axios from 'axios'
import axios from './axios'
// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// 导入富文本编辑器对应的样式
// import 'quill/dist/quill.core.css' // import styles
// import 'quill/dist/quill.snow.css' // for snow theme
// import 'quill/dist/quill.bubble.css' // for bubble theme

// 配置响应拦截器，token时间过长会过期，后台返回401，因此需要重新登陆，强制跳转至登陆页面
axios.interceptors.response.use(function(response) {
        // 正常响应处理
        return response
    },
    function(error) {
        // 非正常响应处理(包括401)
        console.dir(error)
        if (error.response.status === 401) {
            // token失效(token在服务器端已经失效了，2个小时时效)
            // 强制用户重新登录系统，以刷新服务器端的token时效
            router.push('/login')
                // 清楚所有缓存
            localStorage.clear()
            throw error
        }
        throw error
    }
)

// 将axios挂载到Vue原型对象上
Vue.prototype.$axios = axios
Vue.prototype.$http = axios

Vue.config.productionTip = false
    // 全局注册组件
Vue.component('tree-table', TreeTable)
Vue.prototype.$echarts = echarts
    // 将富文本编辑器进行全局注册
Vue.use(VueQuillEditor /* { default global options } */ )
Vue.use(Vuex)
    // 自定义格式化时间的全局过滤器
Vue.filter('dataFormat', function(originVal) {
    const dt = new Date(originVal)
    const year = dt.getFullYear()
    const mon = (dt.getMonth() + 1 + '').padStart(2, '0')
    const day = (dt.getDate() + '').padStart(2, '0')
    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    return `${year}-${mon}-${day} ${hh}:${mm}:${ss}`
})

new Vue({
    router,
    // store,
    render: h => h(App)
}).$mount('#app')