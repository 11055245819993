import axios from 'axios'

// 导入NProgress包对应的js和css
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const instance = axios.create({
    baseURL: 'https://matwebapi.weilanauto.com/api',
    // 请求超时时间
    timeout: 30000,
    // headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    // }
})

// 在 request 拦截器中，显示进度条 NProgress.start()
// 设置axios请求拦截器
instance.interceptors.request.use(config => {
    NProgress.start()

    // 处理401报错问题，即身份认证token
    const tokens = localStorage.getItem('token')
    if (!tokens) {
        return config
    }

    config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('token')
    return config
})

// 在 response 拦截器中，隐藏进度条 NProgress.done()
instance.interceptors.response.use(config => {
    NProgress.done()
    return config
})

export default instance